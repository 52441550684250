var state = {
  includedComponents: []
};
var mutations = {
  includedComponents: function includedComponents(state, data) {
    state.includedComponents = data;
  }
};
export default {
  state: state,
  mutations: mutations
};