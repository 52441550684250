exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#304156",
	"menuHover": "#00adb5",
	"subMenuBg": "#00adb5",
	"subMenuHover": "#00adb5",
	"sideBarWidth": "200px",
	"btnprimary": "#00adb5",
	"btnprimaryColor": "#fff",
	"btnprimaryHover": "rgba(0,173,181,0.9)",
	"btnprimaryColorHover": "#fff"
};