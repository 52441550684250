//
//
//
//
//
//

export default {
  name: 'App',
  data: function data() {
    return {
      isRouterAlive: true
    };
  },
  computed: {
    aid: function aid() {
      return this.$store.state.user.aid;
    }
  },
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  mounted: function mounted() {
    console.log(this.$router);
    console.log(this.$store.state.user.aid);
  },
  methods: {
    reload: function reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
        console.log(1010);
        // this.$router.push({ path: '/rds_machineOrder' })
      });
    }
  }
};