import { login, logout as _logout, authorization } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    name: '',
    avatar: ''
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_USER_ID: function SET_USER_ID(state, user_id) {
    state.user_id = user_id;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_RULES: function SET_RULES(state, rules) {
    state.rules = rules;
  }
};
var actions = {
  // user login
  // login({
  //   commit
  // }, userInfo) {
  //   const {
  //     username,
  //     password,
  //     googleCode
  //   } = userInfo
  //   return new Promise((resolve, reject) => {
  //     login({
  //       username: username.trim(),
  //       password: password,
  //       google_code: googleCode
  //     }).then(response => {
  //       console.log('response')
  //       console.log(response)
  //       console.log('response')
  //       // const {
  //       //   data
  //       // } = response
  //       // commit('SET_TOKEN', data.token)
  //       // setToken(data.token)
  //       resolve()
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },
  // authorization({
  //   commit
  // }, token) {
  //   commit('SET_TOKEN', getToken())
  //   return new Promise((resolve, reject) => {
  //     authorization()
  //       .then(res => {
  //         console.log(res)
  //         // if (parseInt(res.status) === 401) {
  //         //   reject(new Error('token error'))
  //         // } else {
  //         resolve(res.data.rules.page)
  //         commit('SET_RULES', res.data.rules.component)
  //         commit('SET_NAME', res.username)
  //         commit('SET_USER_ID', res.user_id)
  //         commit(
  //           'SET_AVATAR',
  //           'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
  //         )
  //         // commit('setNickName', res.data.username)
  //         // }
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // },
  // get user info
  // getInfo({
  //   commit,
  //   state
  // }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo(state.token).then(response => {
  //       const {
  //         data
  //       } = response
  //       if (!data) {
  //         reject('Verification failed, please Login again.')
  //       }
  //       const {
  //         name,
  //         avatar
  //       } = data
  //       commit('SET_NAME', name)
  //       commit('SET_AVATAR', avatar)
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },
  // user logout
  logout: function logout(_ref) {
    var commit = _ref.commit,
      state = _ref.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        removeToken(); // must remove  token  first
        resetRouter();
        commit('RESET_STATE');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};