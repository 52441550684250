import request from '@/utils/request';
export function login(data) {
  console.log('ooooooo');
  console.log(data);
  return request({
    url: '/v1/login',
    method: 'post',
    data: data
  });
}
// 退出登陆
export function logout() {
  return request({
    url: '/v1/login_out',
    method: 'post'
  });
}
// 获取用户列表
export function userList(params) {
  return request({
    url: 'v1/user',
    method: 'get',
    params: params
  });
}
// 创建用户
export function createUser(data) {
  return request({
    url: 'v1/user',
    method: 'post',
    data: data
  });
}
// 创建或重置用户token
export function userToken(data) {
  return request({
    url: 'v1/user_token',
    method: 'post',
    data: data
  });
}
export function getToken() {
  return request({
    url: 'v1/user_token',
    method: 'get'
  });
}
// 删除用户
export function deleteUser(data) {
  return request({
    url: 'v1/user',
    method: 'delete',
    data: data
  });
}
// 重置密码
export function reset_passwd(data) {
  return request({
    url: 'v1/reset_password',
    method: 'post',
    data: data
  });
}
export var password = function password(data) {
  return request({
    url: '/api/sso/v2/reset_pw/',
    method: 'post',
    data: data
  });
};
export var authorization = function authorization() {
  return request({
    url: '/api/sso/v2/auth/',
    method: 'get'
  });
};

// 用户日志
export function userLog(params) {
  return request({
    url: 'v1/request_log',
    method: 'get',
    params: params
  });
}