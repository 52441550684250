import Cookies from 'js-cookie';
import config from '@/config';
export function getToken() {
  return Cookies.get(config.TokenKey);
  // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2Nzk0NjM3MjYsImRhdGEiOnsidXNlcl9pZCI6Nzg1MSwidXNlcm5hbWUiOiJzb2xvIiwiZW1haWwiOiJqc3NvbG9AaXRjb202NjYuY29tIiwiaXNfc3VwZXIiOnRydWV9fQ.WNJekPdQYCy2WtyWjnQJFg1ECk15c-4UyFRwzkESE2A'
}

export function setToken(token) {
  return Cookies.set(config.TokenKey);
}
export function removeToken() {
  return Cookies.remove(config.TokenKey);
}