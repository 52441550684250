import "/home/p1om/jenkins/workspace/workspace/mccenter-fat-f/node_modules/core-js/modules/es6.array.iterator.js";
import "/home/p1om/jenkins/workspace/workspace/mccenter-fat-f/node_modules/core-js/modules/es6.promise.js";
import "/home/p1om/jenkins/workspace/workspace/mccenter-fat-f/node_modules/core-js/modules/es6.object.assign.js";
import "/home/p1om/jenkins/workspace/workspace/mccenter-fat-f/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss'; // global css
import '@/styles/icon.css';
import moment from 'moment';
import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon
import '@/permission'; // permission control
import "../src/directive/directives";
import formCreate from '@form-create/element-ui';
Vue.use(formCreate);
import vueztree from 'vue-ztree-2.0/dist/vue-ztree-2.0.umd.min.js';
import 'vue-ztree-2.0/dist/vue-ztree-2.0.css';
import UmyUi from 'umy-ui';
import 'umy-ui/lib/theme-chalk/index.css'; // 引入样式
import VueClipboard from 'vue-clipboard2';
Vue.use(UmyUi);
Vue.use(VueClipboard);
Vue.use(vueztree);
Vue.prototype.$moment = moment;
console.log(process.env.VUE_APP_BASE_API);
console.log(1111);
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
Vue.use(ElementUI, {
  // locale
});
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');