import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
export var routerMap = [{
  path: '/',
  component: Layout,
  alwaysShow: true,
  redirect: '/index',
  name: 'index',
  meta: {
    title: '首页',
    icon: 'el-icon-s-home'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/index/index');
    },
    name: 'index',
    meta: {
      title: '首页',
      icon: 'el-icon-house'
    }
  }]
}, {
  path: '/node',
  component: Layout,
  alwaysShow: true,
  name: 'node',
  meta: {
    title: '服务器',
    icon: 'el-icon-coin'
  },
  children: [{
    path: 'nodeinfo',
    component: function component() {
      return import('@/views/node/node');
    },
    name: 'nodeinfo',
    meta: {
      title: '节点',
      icon: 'el-icon-monitor'
    }
  }, {
    path: 'nodezone',
    component: function component() {
      return import('@/views/node/nodezone');
    },
    name: 'nodezone',
    meta: {
      title: '分组',
      icon: 'el-icon-collection'
    }
  }]
}, {
  path: '/domain',
  component: Layout,
  alwaysShow: true,
  name: 'domain',
  meta: {
    title: 'CDN加速',
    icon: 'el-icon-odometer'
  },
  children: [{
    path: 'domainsiteinfo',
    component: function component() {
      return import('@/views/CDN/domainsiteinfo');
    },
    name: 'domainsiteinfo',
    meta: {
      title: '站点加速',
      icon: 'el-icon-stopwatch'
    }
  }, {
    path: 'proxyinfo',
    component: function component() {
      return import('@/views/CDN/proxyinfo');
    },
    name: 'proxyinfo',
    meta: {
      title: '四层加速',
      icon: 'el-icon-files'
    }
  }, {
    path: 'domainssl',
    component: function component() {
      return import('@/views/CDN/domainssl');
    },
    name: 'domainssl',
    meta: {
      title: 'SSL证书',
      icon: 'el-icon-tickets'
    }
  }, {
    path: 'acme',
    component: function component() {
      return import('@/views/CDN/acme');
    },
    name: 'acme',
    meta: {
      title: '免费证书申请',
      icon: 'el-icon-document'
    }
  }, {
    path: 'domainerrorpage',
    component: function component() {
      return import('@/views/CDN/domainerrorpage');
    },
    name: 'domainerrorpage',
    meta: {
      title: '自定义错误页面',
      icon: 'el-icon-document-delete'
    }
  }]
}, {
  path: '/dsetting',
  component: Layout,
  alwaysShow: true,
  name: 'dsetting',
  meta: {
    title: '缓存配置',
    icon: 'el-icon-receiving'
  },
  children: [{
    path: 'globaloptions',
    component: function component() {
      return import('@/views/dsetting/globaloptions');
    },
    name: 'globaloptions',
    meta: {
      title: '全局配置',
      icon: 'el-icon-sunny'
    }
  }, {
    path: 'options',
    component: function component() {
      return import('@/views/dsetting/options');
    },
    name: 'options',
    meta: {
      title: '缓存配置',
      icon: 'el-icon-receiving'
    }
  }
  // {
  //   path: 'locations',
  //   component: () => import('@/views/dsetting/locations'),
  //   name: 'locations',
  //   meta: {
  //     title: '匹配规则',
  //     icon: 'el-icon-c-scale-to-original'
  //   }
  // }
  ]
}, {
  path: '/cache',
  component: Layout,
  alwaysShow: true,
  name: 'cache',
  meta: {
    title: '刷新/预加载',
    icon: 'el-icon-refresh-right'
  },
  children: [{
    path: 'refresh',
    component: function component() {
      return import('@/views/load/refresh');
    },
    name: 'refresh',
    meta: {
      title: '刷新任务',
      icon: 'el-icon-refresh'
    }
  }, {
    path: 'preload',
    component: function component() {
      return import('@/views/load/preload');
    },
    name: 'preload',
    meta: {
      title: '预加载任务',
      icon: 'el-icon-refresh-right'
    }
  }, {
    path: 'clear_cache',
    component: function component() {
      return import('@/views/load/clear_cache');
    },
    name: 'clear_cache',
    meta: {
      title: '清理CDN缓存',
      icon: 'el-icon-refresh-right'
    }
  }]
}, {
  path: '/set_meal',
  component: Layout,
  alwaysShow: true,
  name: 'set_meal',
  meta: {
    title: '套餐管理',
    icon: 'el-icon-shopping-cart-1'
  },
  children: [{
    path: 'sale',
    component: function component() {
      return import('@/views/setmeal/sale');
    },
    name: 'sale',
    meta: {
      title: '销售套餐',
      icon: 'el-icon-shopping-bag-1'
    }
  }, {
    path: 'bought',
    component: function component() {
      return import('@/views/setmeal/bought');
    },
    name: 'bought',
    meta: {
      title: '已购套餐',
      icon: 'el-icon-wallet'
    }
  }]
}, {
  path: '/statistics',
  component: Layout,
  alwaysShow: true,
  name: 'statistics',
  meta: {
    title: '统计分析',
    icon: 'el-icon-pie-chart'
  },
  children: [{
    path: 'overview',
    component: function component() {
      return import('@/views/chart/overview');
    },
    name: 'overview',
    meta: {
      title: '概览',
      icon: 'el-icon-data-analysis'
    }
  }, {
    path: 'domain-ranking',
    component: function component() {
      return import('@/views/chart/domainRanking');
    },
    name: 'domain-ranking',
    meta: {
      title: 'URL排行',
      icon: 'el-icon-link'
    }
  }, {
    path: 'domain-status-code',
    component: function component() {
      return import('@/views/chart/domainStatusCode');
    },
    name: 'domain-status-code',
    meta: {
      title: '状态码',
      icon: 'el-icon-wind-power'
    }
  }, {
    path: 'domain-hit',
    component: function component() {
      return import('@/views/chart/domainHit');
    },
    name: 'domain-hit',
    meta: {
      title: '命中率',
      icon: 'el-icon-aim'
    }
  }, {
    path: 'ip-ranking',
    component: function component() {
      return import('@/views/chart/ipRanking');
    },
    name: 'ip-ranking',
    meta: {
      title: 'IP排名',
      icon: 'el-icon-place'
    }
  }, {
    path: 'ua-ranking',
    component: function component() {
      return import('@/views/chart/uaRanking');
    },
    name: 'ua-ranking',
    meta: {
      title: 'UA排名',
      icon: 'el-icon-document-remove'
    }
  }, {
    path: 'referer-ranking',
    component: function component() {
      return import('@/views/chart/refererRanking');
    },
    name: 'refresh',
    meta: {
      title: 'Referer排名',
      icon: 'el-icon-guide'
    }
  }, {
    path: 'domain-attack',
    component: function component() {
      return import('@/views/chart/domainAttack');
    },
    name: 'domain-attack',
    meta: {
      title: '攻击统计',
      icon: 'el-icon-lightning'
    }
  }]
}, {
  path: '/config',
  component: Layout,
  alwaysShow: true,
  name: 'config',
  meta: {
    title: '系统配置',
    icon: 'el-icon-setting'
  },
  children: [{
    path: 'dns',
    component: function component() {
      return import('@/views/dns/dns');
    },
    name: 'dns',
    meta: {
      title: 'DNS配置',
      icon: 'el-icon-cloudy'
    }
  }, {
    path: 'cm_agent',
    component: function component() {
      return import('@/views/ECS/agent');
    },
    name: 'cm_agent',
    meta: {
      title: 'Agent管理',
      icon: 'el-icon-box'
    }
  }]
}, {
  path: '/account',
  component: Layout,
  alwaysShow: true,
  name: 'account',
  meta: {
    title: '用户中心',
    icon: 'el-icon-user'
  },
  children: [{
    path: 'userlist',
    component: function component() {
      return import('@/views/user/account');
    },
    name: 'userlist',
    meta: {
      title: '用户列表',
      icon: 'el-icon-user'
    }
  }, {
    path: 'userlog',
    component: function component() {
      return import('@/views/user/userLog');
    },
    name: 'userlog',
    meta: {
      title: '用户日志',
      icon: 'el-icon-notebook-2'
    }
  }]
}, {
  path: '/celery',
  component: Layout,
  alwaysShow: true,
  name: 'celery',
  meta: {
    title: '服务监控',
    icon: 'el-icon-video-camera'
  },
  children: [{
    path: 'celery',
    component: function component() {
      return import('@/views/taskMonitor/celeryTask');
    },
    name: 'celery',
    meta: {
      title: 'celery监控',
      icon: 'el-icon-data-board'
    }
  }, {
    path: 'operation',
    component: function component() {
      return import('@/views/taskMonitor/operationTask');
    },
    name: 'operation',
    meta: {
      title: '操作监控',
      icon: 'el-icon-toilet-paper'
    }
  }]
}];

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true,
  meta: {
    title: 'login'
  }
}, {
  path: '/reset_passwd',
  component: function component() {
    return import('@/views/user/reset_passwd');
  },
  hidden: true,
  meta: {
    title: 'login'
  }
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    // mode:'hash',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

router.addRoutes(routerMap);
export default router;