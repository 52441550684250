import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bus from "./bus";
import '@/styles/index.scss';
export default {
  data: function data() {
    return {
      tagsList: []
    };
  },
  methods: {
    isActive: function isActive(path) {
      return path === this.$route.fullPath;
    },
    // 关闭单个标签
    closeTags: function closeTags(index) {
      var delItem = this.tagsList.splice(index, 1)[0];
      var item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
      if (item) {
        delItem.path === this.$route.fullPath && this.$router.push(item.path);
      } else {
        this.$router.push('/');
      }
    },
    // 关闭全部标签
    closeAll: function closeAll() {
      this.tagsList = [];
      this.$router.push('/');
    },
    // 关闭其他标签
    closeOther: function closeOther() {
      var _this = this;
      var curItem = this.tagsList.filter(function (item) {
        return item.path === _this.$route.fullPath;
      });
      this.tagsList = curItem;
    },
    // 设置标签
    setTags: function setTags(route) {
      var isExist = this.tagsList.some(function (item) {
        return item.path === route.fullPath;
      });
      if (!isExist) {
        if (this.tagsList.length >= 14) {
          this.tagsList.shift();
        }
        if (route.fullPath.indexOf('br_house') != -1) {
          for (var i = 0; i < this.tagsList.length; i++) {
            console.log(this.tagsList[i]);
            if (this.tagsList[i].path.indexOf('br_house') != -1) {
              this.tagsList.splice(i, 1);
            }
          }
        }
        if (route.fullPath.indexOf('br_offices') != -1) {
          for (var _i = 0; _i < this.tagsList.length; _i++) {
            console.log(this.tagsList[_i]);
            if (this.tagsList[_i].path.indexOf('br_offices') != -1) {
              this.tagsList.splice(_i, 1);
            }
          }
        }
        this.tagsList.push({
          title: route.meta.title,
          path: route.fullPath,
          name: route.matched[1].components.default.name
        });
      }
      bus.$emit('tags', this.tagsList);
    },
    handleTags: function handleTags(command) {
      command === 'other' ? this.closeOther() : this.closeAll();
    }
  },
  computed: {
    showTags: function showTags() {
      return this.tagsList.length > 0;
    }
  },
  watch: {
    $route: function $route(newValue, oldValue) {
      this.setTags(newValue);
    }
  },
  created: function created() {
    var _this2 = this;
    this.setTags(this.$route);
    // 监听关闭当前页面的标签页
    bus.$on('close_current_tags', function () {
      for (var i = 0, len = _this2.tagsList.length; i < len; i++) {
        var item = _this2.tagsList[i];
        if (item.path === _this2.$route.fullPath) {
          if (i < len - 1) {
            _this2.$router.push(_this2.tagsList[i + 1].path);
          } else if (i > 0) {
            _this2.$router.push(_this2.tagsList[i - 1].path);
          } else {
            _this2.$router.push('/');
          }
          _this2.tagsList.splice(i, 1);
          break;
        }
      }
    });
  },
  mounted: function mounted() {
    console.log(this.$route.fullPath);
  }
};