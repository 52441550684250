import axios from 'axios';
import { Message } from 'element-ui';
import { getToken, removeToken } from '@/utils/auth';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  if (getToken()) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['Auth-Token'] = getToken();
    // config.headers['content-type']='application/json'
  } else {}
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */

// response => {
//   const {
//     data,
//     status,
//     request
//   } = response
//   console.log({ data, status, request })
//   return {
//     data,
//     status,
//     request
//   }
// },

function (response) {
  // if (response.data.code !== 20000 && response.data.code !== 0) {
  //   console.log(response)
  //   Message({
  //     message: response.data.msg,
  //     type: 'error',
  //     duration: 5 * 1000
  //   })
  //   return Promise.reject('error')
  // }
  return response.data;
}, function (error) {
  console.log(error.response); // for debug
  if (error.response.status === '401' || error.response.status === 401) {
    // token过期或者是失效
    // commit('SET_ROLES', [])
    removeToken();
    localStorage.removeItem('is_super');
    location.reload();
  } else if (error.response.status === 403 || error.response.status === '403') {
    Message({
      message: "\u4F60\u6CA1\u6709\u6743\u9650, \u8BF7\u8054\u7CFB\u7BA1\u7406\u5458",
      type: 'error',
      duration: 5 * 1000
    });
  } else if (error.response.status === 400 || error.response.status === '400') {
    Message({
      message: error.response.data,
      type: 'error',
      duration: 5 * 1000
    });
  }
  // else {
  //   Message({
  //     message: `${error.response.status}-错误`,
  //     duration: 8,
  //     duration: 5 * 1000
  //   })
  // }
  return Promise.reject(error);
});
export default service;