//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TagsView from "./Tags.vue";
export default {
  name: 'AppMain',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    TagsView: TagsView
  },
  computed: {
    key: function key() {
      return this.$route.path;
    },
    includedComponents: function includedComponents() {
      return this.$store.state.keep.includedComponents;
    }
  }
};