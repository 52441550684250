import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
import app from "./modules/app";
import settings from "./modules/settings";
import user from "./modules/user";
import router from "./modules/router";
import keep from "./modules/keep";
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);
var store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    reducer: function reducer(val) {
      return {
        // 只储存state中的user
        user: val.user
      };
    }
  })],
  modules: {
    keep: keep,
    app: app,
    settings: settings,
    router: router,
    user: user
  },
  getters: getters
});
export default store;