var TokenKey, cdnbaseUrl, update_url;
if (process.env.NODE_ENV === 'ceshi') {
  TokenKey = 'Auth-Token';
  // update_url = 'https://dev.21bcca60.com/api/cmdb2/v1/agent'
  cdnbaseUrl = 'http://3.0.30.37'; //
} else if (process.env.NODE_ENV === 'production') {
  TokenKey = 'Auth-Token';
  // update_url = 'https://c.21bcca60.com/api/cmdb2/v1/agent'
  cdnbaseUrl = 'http://consolenew.cdnyb.net';
} else if (process.env.NODE_ENV === 'pre-prod') {
  TokenKey = 'Auth-Token';
  // update_url = 'https://c.21bcca60.com/api/cmdb2/v1/agent'
  // cdnbaseUrl = 'https://sso.21bcca60.com'
  cdnbaseUrl = 'http://52.77.4.53';
} else {
  TokenKey = 'Auth-Token';
  // cdnbaseUrl = "http://172.18.15.147:9528"
  // oaUrl = 'http://172.18.15.67:8081/'
  cdnbaseUrl = 'http://localhost:9538/';
  // update_url = 'https://dev.21bcca60.com/api/cmdb2/v1/agent'
}

export default {
  /**
   * @description token在Cookie分环境 取不同的key
   */
  TokenKey: TokenKey,
  /**
   * @description
   */
  cdnbaseUrl: cdnbaseUrl,
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,
  /**
   * @description 是否使用国际化，默认为false
   *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
   *              用来在菜单中显示文字
   */
  useI18n: true,
  /**
   * @description api请求基础路径
   */
  baseUrl: {
    // dev: 'https://www.easy-mock.com/mock/5add9213ce4d0e69998a6f51/iview-admin/',
    // dev: 'http://172.16.0.223:9800/',
    // dev: '',
    // dev: 'https://dev.21bcca60.com',
    // dev: 'http://172.18.15.124/',
    pro: 'https://consolenew.cdnyb.net'
    // ceshi: 'https://dev.21bcca60.com'
  },

  domainName: {
    // dev: 'dev.21bcca60.com/api/domain2',
    dev: '172.18.3.209:8002',
    pro: 'consolenew.cdnyb.net/api/domain2',
    ceshi: 'dev.21bcca60.com/api/domain2'
  },
  isHttps: document.location.protocol === 'https:' || false,
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: '/',
  whiteName: ['home', 'domain_dashboard'],
  /**
   * @description 需要加载的插件
   */
  plugin: {
    'error-store': {
      showInHeader: false,
      // 设为false后不会在顶部显示错误日志徽标
      developmentOff: true // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
    }
  }
};