import "core-js/modules/es6.function.name";
import { constantRoutes, routerMap } from '@/router/index';
var state = {
  routers: constantRoutes,
  hasGetRules: false
};
var getters = {
  menuList: function menuList(state) {
    return getMenuByRouter(routerMap, 'all');
  }
};
var mutations = {
  CONCAT_ROUTES: function CONCAT_ROUTES(state, routerList) {
    // state.routers = routerList.concat(constantRoutes)
    state.hasGetRules = true;
  }
};
var getAccesRouterList = function getAccesRouterList(routes, rules) {
  return routes.filter(function (item) {
    // if (rules[item.name]) {
    if (item.children) item.children = getAccesRouterList(item.children, rules);
    return true;
    // } else return false
  });
};

/**
 * @param {Array} list 通过路由列表得到菜单列表
 * @returns {Array}
 */
export var getMenuByRouter = function getMenuByRouter(list, access) {
  var res = [];
  forEach(list, function (item) {
    if (!item.meta || item.meta && !item.meta.hideInMenu) {
      var obj = {
        icon: item.meta && item.meta.icon || '',
        name: item.name,
        path: item.path,
        meta: item.meta
        // alwaysShow: item.alwaysShow
      };

      if ((hasChild(item) || item.meta && item.meta.showAlways) && showThisMenuEle(item, access)) {
        obj.children = getMenuByRouter(item.children, access);
      }
      if (item.meta && item.meta.href) obj.href = item.meta.href;
      if (showThisMenuEle(item, access)) res.push(obj);
    }
  });
  return res;
};
export var hasChild = function hasChild(item) {
  return item.children && item.children.length !== 0;
};
var showThisMenuEle = function showThisMenuEle(item, access) {
  if (item.meta && item.meta.access && item.meta.access.length) {
    if (hasOneOf(item.meta.access, access)) return true;else return false;
  } else return true;
};
/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export var hasOneOf = function hasOneOf(targetarr, arr) {
  return targetarr.some(function (_) {
    return arr.indexOf(_) > -1;
  });
};
export var forEach = function forEach(arr, fn) {
  if (!arr.length || !fn) return;
  var i = -1;
  var len = arr.length;
  while (++i < len) {
    var item = arr[i];
    fn(item, i, arr);
  }
};
var actions = {
  concatRoutes: function concatRoutes(_ref, rules) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      try {
        var routerList = routerMap;
        // 如果全部是true 直接返回
        // if (Object.entries(rules).every(item => item[1])) {
        //   routerList = routerMap
        // } else {
        // routerList = getAccesRouterList(routerMap, rules)
        // }
        commit('CONCAT_ROUTES', routerMap);
        resolve(routerList);
      } catch (err) {
        reject(err);
      }
    });
  }
};
export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};