var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c("router-link", {
                key: "collapse",
                staticClass: "sidebar-logo-link",
                attrs: { to: "/" },
              })
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  staticStyle: { "border-bottom": "1px solid #1d2d3d" },
                  attrs: { to: "/" },
                },
                [
                  _c("img", {
                    staticClass: "sidebar-logo",
                    attrs: { src: require("../../../assets/img/logos.png") },
                  }),
                  _vm._v(" "),
                  _c(
                    "h1",
                    {
                      staticStyle: {
                        display: "inline-block",
                        height: "50px",
                        color: "#e6e6e6",
                        "font-size": "16px",
                        margin: "0",
                        "vertical-align": "middle",
                      },
                    },
                    [_vm._v("自 建 C D N")]
                  ),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }