//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Navbar, Sidebar, AppMain } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import watermarkH from 'watermark-dom';
import watermarkD from '@/utils/watermark';
export default {
  name: 'Layout',
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  },
  mounted: function mounted() {
    var date = new Date();
    var nowMonth = date.getMonth() + 1;
    var strDate = date.getDate();
    var fenge = '.';
    var nowDate = date.getFullYear() + fenge + nowMonth + fenge + strDate;

    // 隐水印
    watermarkH.init({
      watermark_txt: window.localStorage.getItem('name') + ' ' + nowDate,
      watermark_x: 0,
      // 水印起始位置Y轴坐标
      watermark_y: 0,
      // 水印起始位置Y轴坐标
      watermark_rows: 10,
      // 水印行数
      watermark_cols: 10,
      // 水印列数
      watermark_x_space: 25,
      // 水印x轴间隔
      watermark_y_space: 25,
      // 水印y轴间隔
      watermark_font: '微软雅黑',
      // 水印字体
      watermark_color: '#000',
      // 水印字体颜色
      watermark_fontsize: '20px',
      // 水印字体大小
      watermark_alpha: 0.005,
      // 水印透明度，要求设置在大于等于0.005
      watermark_width: 100,
      // 水印宽度
      watermark_height: 50,
      // 水印长度
      watermark_angle: 30 // 水印倾斜度数});
    });
    // 显水印
    watermarkD.set(window.localStorage.getItem('name') + ' ' + nowDate);
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};